<template>
  <div class="atom-input atom-input__wrapper">
    <div
      class="atom-input-text"
      :class="{
        'has-input': hasInput,
        'has-focus': hasFocus,
        'is-disabled': isDisabled,
        'has-icon-left': iconPosition === 'left' && icon !== 'none' && inputType !== 'password',
        'has-error': showErrors,
      }"
    >
      <label v-if="label" class="atom-input-text__label" :for="name">
        {{ label + (isRequired ? ' *' : '') }}
      </label>

      <IonIcon
        v-if="icon !== 'none' && iconPosition === 'left' && inputType !== 'password'"
        :name="inputType === 'password' ? 'reveal' : icon"
        class="atom-input-text__icon is-left"
      />

      <input
        :id="name"
        v-model="inputValue"
        v-maska
        :name="name"
        :type="computedInputType"
        :placeholder="placeholder"
        :disabled="isDisabled"
        class="atom-input-text__input"
        :data-maska="mask"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      >

      <IonIcon
        v-if="(icon !== 'none' && iconPosition === 'right') || inputType === 'password'"
        class="atom-input-text__icon is-right"
        :class="{ 'has-pointer': inputType === 'password' }"
        :name="inputType === 'password' ? 'reveal' : icon"
        @click="inputType === 'password' ? toggleVisibility() : null"
        @keydown="inputType === 'password' ? toggleVisibility() : null"
      />
    </div>

    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
      />
    </transition>
  </div>
</template>

<script setup>
/**
 * This component provides an input field with label, icon, and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {string} [props.icon='none'] - The icon to display in the input field.
 * @param {string} [props.iconPosition='right'] - The position of the icon in the input field.
 * @param {string} [props.inputType='text'] - The type of input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: false,
        default: '',
    },

    placeholder: {
        type: String,
        required: false,
        default: '',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => !value.includes(' '),
    },

    initialValue: {
        type: [String, Number],
        default: '',
        validator: (value) => typeof value === 'string' || typeof value === 'number',
    },

    icon: {
        type: String,
        default: 'none',
        validator: (value) => [
            'none',
            'profile',
            'date',
        ].includes(value),
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    inputType: {
        type: String,
        default: 'text',
        validator: (value) => [
            'text',
            'email',
            'number',
            'password',
        ].includes(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    mask: {
        type: String,
        default: '',
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value, isInitial) => {
    if (props.isDisabled) return;

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
        isInitial,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue, false);
});

handleData(inputValue.value, true);

/*
    States
*/
const {
    setHover,
    setFocus,
    hasFocus,
    hasInput,
    showErrors,
    showErrorList,
    isRequired,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom values for this kind of input
*/
/* Input Type */
const passwordVisible = ref(false);
const toggleVisibility = () => {
    passwordVisible.value = !passwordVisible.value;
};

const computedInputType = computed(() => {
    /* If input is password, return text or password */
    if (props.inputType === 'password') {
        return passwordVisible.value ? 'text' : 'password';
    }

    /* Else, return input type */
    return props.inputType;
});

watch(() => props.initialValue, () => {
    setInitialValue();
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    .block-intercompany-movement-settings__row__desc & {
        max-width: unset;
    }
}

.atom-input-text {
    @include formFieldBasicsText;
    @include fluid(--horizontal-padding, 20px, 32px);

    &.has-icon-left {
        @include fluid(--horizontal-padding, 56px, 74px);
    }
}

.atom-input-text__label {
    @include formFieldLabelText;
}

.atom-input-text__input {
    @include formFieldInputText;

    .block-intercompany-movement-settings__row__quantity &,
    .block-intercompany-movement-settings__row__ppu & {
        padding: 19px;
    }
}

.atom-input-text__icon {
    @include formFieldInputIcon;

    path {
        transition: all 0.2s ease-in-out;
    }

    .has-focus & {
        path {
            stroke: var(--c-primary);
            transition: all 0.2s ease-in-out;
        }
    }
}
</style>
